<template>
  <div class="px-3">
    <h3 class="mb-3">Preencha seus dados para se registrar</h3>
    <form-user button-rounded button-text="Registrar" @submit="register">
      <template #extra-actions>
        <v-btn
          large
          class="mt-3"
          color="secondary"
          block
          text
          @click="backToLogin"
        >
          Voltar ao login
        </v-btn>
      </template>
    </form-user>
  </div>
</template>

<script>
import { create } from "@/services/external/users-service";

export default {
  components: {
    "form-user": () =>
      import("@/domains/user/shared/presentation/components/FormUser"),
  },
  data: () => ({}),
  methods: {
    backToLogin() {
      this.$router.push({ name: "auth.login" });
    },
    async register(user) {
      try {
        this.$store.dispatch("loading/openDialog");
        await create(user);
        this.$toasted.global.success({
          message: "Registro realizado! Enviamos um e-mail de confirmação!",
        });
        this.dialog = false;
        this.backToLogin();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
